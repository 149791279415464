import React, { forwardRef } from 'react';
import 'twin.macro';
import PropTypes from 'prop-types';

const CustomInput = forwardRef(
  ({ value, onClick, placeholderInputText }, ref) => {
    return (
      <div tw="grid md:grid-cols-12 rounded border border-electric-green p-2 mt-4 mb-2 pr-2 text-light-grey text-xs hover:border-yellow-green hover:text-light-grey">
        <svg
          tw="w-6 h-6 inline-block mr-2 col-span-3"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          width="485.213px"
          height="485.212px"
          viewBox="0 0 485.213 485.212"
          style={{ enableBackground: 'new 0 0 485.213 485.212' }}
          xmlSpace="preserve"
        >
          <g>
            <path d="M60.652,75.816V15.163C60.652,6.781,67.433,0,75.817,0c8.38,0,15.161,6.781,15.161,15.163v60.653   c0,8.38-6.781,15.161-15.161,15.161C67.433,90.978,60.652,84.196,60.652,75.816z M318.424,90.978   c8.378,0,15.163-6.781,15.163-15.161V15.163C333.587,6.781,326.802,0,318.424,0c-8.382,0-15.168,6.781-15.168,15.163v60.653   C303.256,84.196,310.042,90.978,318.424,90.978z M485.212,363.906c0,66.996-54.312,121.307-121.303,121.307   c-66.986,0-121.302-54.311-121.302-121.307c0-66.986,54.315-121.3,121.302-121.3C430.9,242.606,485.212,296.919,485.212,363.906z    M454.89,363.906c0-50.161-40.81-90.976-90.98-90.976c-50.166,0-90.976,40.814-90.976,90.976c0,50.171,40.81,90.98,90.976,90.98   C414.08,454.886,454.89,414.077,454.89,363.906z M121.305,181.955H60.652v60.651h60.653V181.955z M60.652,333.584h60.653V272.93   H60.652V333.584z M151.629,242.606h60.654v-60.651h-60.654V242.606z M151.629,333.584h60.654V272.93h-60.654V333.584z    M30.328,360.891V151.628h333.582v60.653h30.327V94c0-18.421-14.692-33.349-32.843-33.349h-12.647v15.166   c0,16.701-13.596,30.325-30.322,30.325c-16.731,0-30.326-13.624-30.326-30.325V60.651H106.14v15.166   c0,16.701-13.593,30.325-30.322,30.325c-16.733,0-30.327-13.624-30.327-30.325V60.651H32.859C14.707,60.651,0.001,75.579,0.001,94   v266.892c0,18.36,14.706,33.346,32.858,33.346h179.424v-30.331H32.859C31.485,363.906,30.328,362.487,30.328,360.891z    M303.256,242.606v-60.651h-60.648v60.651H303.256z M428.231,334.359c-5.923-5.928-15.519-5.928-21.437,0l-53.602,53.602   l-32.17-32.166c-5.923-5.923-15.518-5.923-21.44,0s-5.923,15.519,0,21.44l42.886,42.886c2.959,2.959,6.842,4.438,10.725,4.438   c3.874,0,7.753-1.479,10.716-4.438l64.322-64.326C434.153,349.872,434.153,340.282,428.231,334.359z" />
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
        <input
          onClick={onClick}
          className="dateInput"
          value={value}
          type="text"
          placeholder={placeholderInputText}
          tw="col-span-9"
        />
      </div>
    );
  }
);
CustomInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholderInputText: PropTypes.string.isRequired
};

export default CustomInput;
