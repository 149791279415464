import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';

// Import Container
import UserData from '@providers/UserData';

// Components
import Loading from '@components/Loading';

// Env
import env from '@env';

// Assets
import squareButton from '@assets/SquareButton.png';

const SquareAuth = () => {
  const userData = UserData.useContainer();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const codeQuery = queryString.parse(window.location.search).code;
  const responseTypeQuery = queryString.parse(window.location.search)
    .response_type;

  useEffect(() => {
    const options = {
      method: 'GET',
      url: `${env().SQUARE_SERVICE}/auth/`,
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      },
      params: {
        code: codeQuery,
        responseType: responseTypeQuery
      }
    };
    axios(options)
      .then(({ data }) => {
        //
        userData.setToken(data.data.access_token);
        userData.setMerchantId(data.data.merchant_id);
        localStorage.setItem('token', data.data.access_token);
        localStorage.setItem('merchantId', data.data.merchant_id);
        history.push('/locations');
        //
      })
      .catch(error => {
        setLoading(false);
        history.push('/');
      });
  }, []);

  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <div css={[tw`flex flex-col w-1/5 p-4 bg-white rounded`]}>
          <h3 tw="text-2xl leading-none m-0">Step 1</h3>
          <p tw="mt-3 text-sm">Connect to your Square acccount</p>
          <div>
            <div tw="w-3/4 items-center text-center m-auto">
              <div
                css={[
                  tw`p-2 pb-3 border border-solid rounded cursor-pointer border-electric-green hover:border-yellow-green`
                ]}
              >
                <a
                  css={[tw`flex flex-col items-center`]}
                  href={`${env().SQUARE_URL}/oauth2/authorize?client_id=${
                    env().SQUARE_APP_ID
                  }&scope=${env().SQUARE_SCOPES}&session=false`}
                >
                  <span tw="text-light-grey text-sm leading-none mb-3">
                    Click here to Authenticate with
                  </span>
                  <img tw="w-24" src={squareButton} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SquareAuth;
