/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';

// Env
import env from '@env';

// Assets
import squareButton from '@assets/SquareButton.png';

/**
 * @function ConnectToSquare
 * Component that first verifies the user's email with oAuth then displays
 * a button for them to connect to Square
 */
const ConnectToSquare = () => {
  // const redirect = () => {
  //   setTimeout(() => {
  //     location.href = `${env().SQUARE_URL}/oauth2/authorize?client_id=${
  //       env().SQUARE_APP_ID
  //     }&scope=${env().SQUARE_SCOPES}&session=false`;
  //   }, 5000);
  // };

  return (
    <div css={[tw`flex flex-col w-1/5 p-4 bg-white rounded`]}>
      <h3 tw="text-2xl leading-none m-0">Step 1</h3>
      <p tw="mt-3 text-sm">Connect to your Square acccount</p>
      <div>
        <div tw="w-3/4 items-center text-center m-auto">
          <div
            css={[
              tw`p-2 pb-3 border border-solid rounded cursor-pointer border-electric-green hover:border-yellow-green`
            ]}
          >
            <a
              css={[tw`flex flex-col items-center`]}
              href={`${env().SQUARE_URL}/oauth2/authorize?client_id=${
                env().SQUARE_APP_ID
              }&scope=${env().SQUARE_SCOPES}&session=false`}
            >
              <span tw="text-light-grey text-sm leading-none mb-3">
                Click here to Authenticate with
              </span>
              <img tw="w-24" src={squareButton} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectToSquare;
