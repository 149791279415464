/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';
import axios from 'axios';
import useState from 'react-usestateref';

// Env
import env from '@env';

// Assets
import loading from '../../assets/loading.svg';

// Components
import CustomInput from './CustomInput';

// utils
import formatNumber from '@utils/formatNumber';
/**
 * @function SelectRangeDates
 */
const SelectRangeDates = () => {
  const history = useHistory();
  const location = useLocation().state;
  if (!location) {
    history.push('/locations');
  }
  const { locationId } = location;
  const token = localStorage.getItem('token');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cursor, setCursor] = useState(undefined);
  const [dataArray, setDataArray, dataArrayRef] = useState([]);
  const [finishData, setfinishData, finishDataRef] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [statusInfo, setStatusInfo] = useState({ message: '', process: false });

  const makeCSV = content => {
    let csv = '';
    content.forEach(value => {
      value.forEach((item, i) => {
        let result;
        if (typeof item === 'number') {
          result = item;
        } else {
          const innerValue =
            item === null || item === undefined ? '' : item.toString();
          result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            result = `"${result}"`;
          }
        }
        if (i > 0) {
          csv += ',';
        }
        csv += result;
      });
      csv += '\n';
    });
    return csv;
  };

  const processData = () => {
    const contents = [];
    contents.push([
      'Date',
      'Hour',
      'Category',
      'Item',
      'Qty',
      'SKU',
      'Gross Sales',
      'Discounts',
      'Net Sales',
      'Tax',
      'Total Order',
      'Invoice Id',
      'Transaction ID',
      'Payment ID',
      'Next Payment',
      'Device Name',
      'Notes',
      'Description',
      'Customer Name',
      'Count',
      'Tip',
      'Invoice status',
      'Second Technician',
      'Job Complete?'
    ]);
    const data = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of dataArray) {
      const dateInvoice = new Date(item.createdAt);

      const dateInvoiceFormatted = new Date(
        dateInvoice.getFullYear(),
        dateInvoice.getMonth(),
        dateInvoice.getDate(),
        0,
        0,
        0
      );
      if (
        Date.parse(new Date(dateInvoiceFormatted)) >= Date.parse(startDate) &&
        Date.parse(new Date(dateInvoiceFormatted)) <= Date.parse(endDate)
      ) {
        const totalAmount = item.paymentRequests.reduce(
          (a, b) => a + (b.computedAmountMoney.amount || 0),
          0
        );
        if (item?.orderId !== undefined) {
          if (item?.orders[0]?.lineItems !== undefined) {
            item?.orders[0]?.lineItems?.forEach(element => {
              const categoryName =
                element?.catalogObjectData?.relatedObjects[0]?.itemData
                  ?.catalogObjectDataCategory?.object?.categoryData?.name;
              const sku =
                element?.catalogObjectData?.object?.itemVariationData?.sku;
              contents.push([
                dateInvoice.toISOString().substring(0, 10),
                `${dateInvoice.getHours()}:${dateInvoice.getMinutes()}:${dateInvoice.getSeconds()}`,
                `${categoryName ? categoryName : 'No Category'}`,
                element.name,
                element.quantity,
                `${sku ? sku : 'No SKU'}`,
                formatNumber(element?.grossSalesMoney?.amount),
                formatNumber(element?.totalDiscountMoney?.amount),
                formatNumber(element?.totalMoney?.amount),
                formatNumber(element?.totalTaxMoney?.amount),
                formatNumber(totalAmount),
                item.id,
                item.orders[0]?.tenders[0]?.transactionId.substring(0, 4),
                item.orders[0]?.tenders[0]?.id.substring(0, 4),
                formatNumber(item?.nextPaymentAmountMoney?.amount),
                item?.orders[0]?.tenders[0]?.payment?.cardDetails?.deviceDetails
                  ?.deviceName,
                element.note,
                element.catalogObjectData?.relatedObjects[0]?.itemData
                  ?.description,
                `${item.primaryRecipient.givenName} ${item.primaryRecipient.familyName}`,
                element.quantity,
                formatNumber(item.orders[0]?.totalTipMoney?.amount),
                item.status,
                item.customFields?.some(
                  custom => custom.label === 'Second Technician'
                )
                  ? `${item.customFields[0].value}`
                  : '',
                item.customFields?.some(
                  custom => custom.label === 'Job Complete?'
                )
                  ? `${item.customFields[0].value}`
                  : ''
              ]);
            });
          } else {
            contents.push([
              dateInvoice.toISOString().substring(0, 10),
              `${dateInvoice.getHours()}:${dateInvoice.getMinutes()}:${dateInvoice.getSeconds()}`,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              formatNumber(totalAmount),
              item.id,
              item.orders[0]?.tenders[0]?.transactionId.substring(0, 4),
              item.orders[0]?.tenders[0]?.id.substring(0, 4),
              formatNumber(item?.nextPaymentAmountMoney?.amount),
              item?.orders[0]?.tenders[0]?.payment?.cardDetails?.deviceDetails
                ?.deviceName,
              '',
              '',
              `${item.primaryRecipient.givenName} ${item.primaryRecipient.familyName}`,
              '',
              formatNumber(item.orders[0]?.totalTipMoney?.amount),
              item.status,
              item.customFields?.some(
                custom => custom.label === 'Second Technician'
              )
                ? `${item.customFields[0].value}`
                : '',
              item.customFields?.some(
                custom => custom.label === 'Job Complete?'
              )
                ? `${item.customFields[0].value}`
                : ''
            ]);
          }
        } else {
          contents.push([
            new Date(item.createdAt).toISOString().substring(0, 10),
            `${dateInvoice.getHours()}:${dateInvoice.getMinutes()}:${dateInvoice.getSeconds()}`,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            formatNumber(totalAmount),
            item.id,
            '',
            '',
            '',
            '',
            '',
            '',
            `${item.primaryRecipient.givenName} ${item.primaryRecipient.familyName}`,
            ,
            '',
            '',
            item.status,
            item.customFields?.some(
              custom => custom.label === 'Second Technician'
            )
              ? `${item.customFields[0].value}`
              : '',
            item.customFields?.some(custom => custom.label === 'Job Complete?')
              ? `${item.customFields[0].value}`
              : ''
          ]);
        }
      }
    }

    if (contents.length > 1) {
      setStatusInfo({ message: '', process: false });
      const output = makeCSV(contents);
      const data = new Blob([output], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `data-${Date.parse(new Date())}.csv`);
      tempLink.click();
      contents.pop();
    } else {
      setStatusInfo({
        message: 'There is no information in the selected date range.',
        process: false
      });
    }
  };

  const getData = () => {
    setStatusInfo({
      message: 'Data processing',
      process: true
    });
    if (!finishData) {
      const options = {
        method: 'GET',
        url: `${env().SQUARE_SERVICE}/invoices/`,
        headers: {
          'content-type': 'application/json',
          'cache-control': 'no-cache'
        },
        params: {
          token,
          locationId,
          cursor
        }
      };
      axios(options)
        .then(({ data }) => {
          setCursor(data.data.cursor);
          const dataArrayResponse = [
            ...dataArrayRef.current,
            ...data.data.invoices
          ];
          setDataArray(dataArrayResponse);
          if (data.data.cursor === undefined) {
            setfinishData(true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      processData();
    }
  };

  useEffect(() => {
    if (cursor !== undefined || cursor) {
      getData();
    }

    if (finishDataRef.current) {
      setfinishData(false);
      setDataArray([]);
      processData();
    }
    if (startDate !== null && endDate !== null) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [dataArray, finishData, startDate, endDate]);

  return (
    <div css={[tw`flex flex-col w-3/6 p-4 bg-white rounded md:w-1/5`]}>
      <h3 tw="text-2xl leading-none m-0">Step 3</h3>
      <p tw="mt-3 text-sm">
        Select the date range, and click &quot;download&quot;
      </p>
      <div tw="grid md:grid-cols-2 gap-4">
        <div>
          <DatePicker
            selected={startDate}
            onChange={date => {
              setStartDate(date);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={
              <CustomInput placeholderInputText="Select the Start Date" />
            }
          />
        </div>
        <div>
          <DatePicker
            selected={endDate}
            onChange={date => {
              setEndDate(date);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={addDays(new Date(), 0)}
            customInput={
              <CustomInput placeholderInputText="Select the End Date" />
            }
          />
        </div>
      </div>
      <div tw="flex justify-center mt-4 hover:opacity-50">
        <button
          type="button"
          disabled={disabledButton}
          tw="rounded border border-blue-500 disabled:opacity-50 text-light-grey p-1 w-64 mb-2"
          onClick={() => {
            getData();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            tw="inline-block "
          >
            <defs>
              <style>
                {
                  '.a,.b{fill:none;}.b{stroke:#4187ff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
                }
              </style>
            </defs>
            <path className="a" d="M0,0H24V24H0Z" />
            <path className="b" d="M14,3V7a1,1,0,0,0,1,1h4" />
            <path
              className="b"
              d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z"
            />
            <line className="b" y2="6" transform="translate(12 11)" />
            <path className="b" d="M9,14l3,3,3-3" />
          </svg>
          <span style={{ marginTop: '-2px' }}>
            Download your CSV {disabledButton}
          </span>
        </button>
      </div>
      {statusInfo && (
        <div tw="text-center">
          <p tw="text-lg">
            {statusInfo.message}
            {statusInfo.process && (
              <span>
                <img src={loading} alt="" tw="inline-block w-2/12" />
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectRangeDates;
