// Dependencies
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import 'twin.macro';

// Assets
import headerBackground from '@assets/HeaderBackground.jpg';
import headerDisplay from '@assets/Sunshine-logo.png';
import headerBlackFilter from '@assets/HeaderBlackFilter.svg';

/**
 * @component HeaderContent
 * A simple component for content of the App header
 */
export default () => {
  return (
    <div
      tw="relative flex h-25vh"
      style={{
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover'
      }}
    >
      <img
        src={headerBlackFilter}
        tw="absolute left-0 top-0 bottom-0 z-0"
        alt=""
      />
      <img src={headerDisplay} tw="relative ml-48  self-center z-10" alt="" />
    </div>
  );
};
