/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Env
import env from '@env';

// State Container
// import UserData from '@providers/UserData';

/**
 * @function Locations
 */
const Locations = () => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  if (!token) {
    history.push('/');
  }
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [message, setMessage] = useState('');
  useEffect(() => {
    const options = {
      method: 'GET',
      url: `${env().SQUARE_SERVICE}/location/`,
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      },
      params: {
        token
      }
    };
    axios(options)
      .then(({ data }) => {
        setLocations(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleChange = e => {
    setLocation({
      locationId: e.target.value,
      locationName: e.target.selectedOptions[0].label
    });
  };

  const nextButton = e => {
    setMessage('');
    if (location === '') {
      setMessage('You must select a store to continue');
    }
    history.push({
      pathname: '/SelectRangeDates',
      state: location
    });
  };

  return (
    <div css={[tw`flex flex-col w-1/5 p-4 bg-white rounded`]}>
      <h3 tw="text-2xl leading-none m-0">Step 2</h3>
      <p tw="mt-3 text-sm">Select a location</p>
      <div>
        <div tw="w-3/4 items-center text-center m-auto">
          <select
            onChange={handleChange}
            tw="rounded border-gray-201 border-2 p-4"
          >
            <option tw="text-sm">Select a location</option>
            {locations.map(item => (
              <option key={item.id} value={item.id} tw="text-sm">
                {item.name}
              </option>
            ))}
            ;
          </select>
          <div tw="flex justify-center mt-4 hover:opacity-50">
            <button
              type="button"
              tw="rounded border border-yellow-green disabled:opacity-50 text-light-grey p-1 w-64 mb-2"
              onClick={nextButton}
            >
              Next
            </button>
          </div>
          {message && (
            <div>
              <p tw="text-red-600">{message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Locations;
