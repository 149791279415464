// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

// Assets
import loading from '../../assets/loading.svg';

/**
 * @function Loading
 * React Functional Component
 *
 * Loading shows a loading image if 'show' prop is true
 *
 * @prop {bool} show
 */
const Loading = ({ show }) => {
  if (show) {
    return (
      <div tw="flex justify-center" css={[show ? tw`visible` : tw`invisible`]}>
        <img src={loading} alt="Loading" tw="w-1/4" />
      </div>
    );
  }
  return null;
};

Loading.propTypes = {
  show: PropTypes.bool.isRequired
};

export default Loading;
