// Dependencies
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import 'twin.macro';

// Assets
import SeeedLogo from '@assets/SeeedLogo.png';
/**
 * @component SeedLink
 * a component for a clickable link to the seeed.us website
 */
export default () => {
  return (
    <a href="https://seeed.us/" target="_blank" rel="noreferrer noopener">
      <img tw="w-22" src={SeeedLogo} alt="" />
    </a>
  );
};
