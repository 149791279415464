const formatNumber = (number = 0) => {
  // Convert the number to String
  const inputNumAsStr = number.toString();
  // Manipulate the string and add decimal before two char
  const updatedStr = `${inputNumAsStr.slice(0, -2)}.${inputNumAsStr.slice(-2)}`;
  // Return by converting the string to number again
  // Fix by 2 to stop parseFloat() from stripping zeroes to right of decimal
  return Number(parseFloat(updatedStr)).toFixed(2);
};

export default formatNumber;
