// Dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

// Providers
import UserData from '@providers/UserData';

// Components
import Login from '@components/Login';
import HeaderContent from '@components/HeaderContent';
import SeeedLink from '@components/SeeedLink';
import InfoPage from '@components/InfoPage';
import SquareAuth from '@components/SquareAuth';
import Locations from '@components/Locations';
import SelectRangeDates from '@components/SelectRangeDates';

/** antd layout and styles */
const { Header, Footer, Content } = Layout;
const contentStyles = {
  minHeight: '69vh',
  backgroundColor: 'black',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10
};
const headerStyles = {
  height: 'unset',
  padding: 0
};
const footerStyles = {
  height: '6vh',
  minHeight: '3rem',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0',
  paddingRight: '1.5rem',
  paddingBottom: '1rem',
  backgroundColor: 'black'
};

/**
 * @component App
 *
 * @Provider UserData - object with the users Square oAuth token and merchant ID
 */
const App = () => {
  return (
    <UserData.Provider>
      <Layout>
        <Header style={headerStyles}>
          <HeaderContent />
        </Header>
        <Content style={contentStyles}>
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/info" component={InfoPage} />
              <Route exact path="/squareAuth" component={SquareAuth} />
              <Route exact path="/locations" component={Locations} />
              <Route
                exact
                path="/SelectRangeDates"
                component={SelectRangeDates}
              />
            </Switch>
          </Router>
        </Content>
        <Footer style={footerStyles}>
          <SeeedLink />
        </Footer>
      </Layout>
    </UserData.Provider>
  );
};

export default App;
